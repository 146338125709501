import React, { useCallback, useEffect, useState } from "react";
import {
  FiBriefcase,
  FiCompass,
  FiCreditCard,
  FiDatabase,
  FiDollarSign,
  FiEdit,
  FiMap,
  FiPhone,
  FiUser,
  FiXCircle,
  FiInfo,
} from "react-icons/fi";
import { FormHandles } from "@unform/core";
import { useToasts } from "react-toast-notifications";
import ReactTooltip from "react-tooltip";

import api from "../../services/api";

import { useSidebar } from "../../hooks/SidebarContext";
import { useAuth } from "../../hooks/AuthContext";

import LoocalAutocompleteInput from "../LoocalAutocompleteInput";
import {
  onChangeCurrencyMask,
  onChangePhoneMask,
} from "../../utils/inputAndTextMasks";

import AutocompleteInput from "../AutocompleteInput";
import Input from "../Input";
import InvisibleInput from "../InvisibleInput";
import Radio from "../Radio";
import Select from "../ReactSelect";

import * as Styled from "./styles";
import { CalcOrderResponse } from "../../@types/customTypes";
import VehiclesSelect from "../VehiclesSelect";
import apiCalc from "../../services/apiCalc";

interface NewOrderComponentProps {
  formRef: React.RefObject<FormHandles>;
  index: number;
  childrenStateUpdater: number;
  isDispatch: boolean;
  setIsDispatch: React.Dispatch<React.SetStateAction<boolean>>;
  setNewOrdersCount: React.Dispatch<React.SetStateAction<Array<number>>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedVehicles: React.Dispatch<React.SetStateAction<number[]>>;
  selectedVehicles: number[];
}

const NewOrderComponent: React.FC<NewOrderComponentProps> = ({
  formRef,
  index,
  childrenStateUpdater,
  isDispatch,
  setIsDispatch,
  setNewOrdersCount,
  setIsLoading,
  setSelectedVehicles,
  selectedVehicles,
}) => {
  const [valuePreview, setValuePreview] = useState<{
    value: number;
    isReturnOff: boolean;
  } | null>(null);
  const [returnValue, setReturnValue] = useState("false");

  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();
  const { role, companyLocation, company_id } = useAuth();

  const findCustomerByPhone = useCallback(
    async (phone: string) => {
      try {
        setIsLoading(true);
        const { data: customersFoundResponse } = await api.get(
          `customers?phone=${phone}`,
        );
        const { data: customersFound } = customersFoundResponse;

        if (customersFound[0]) {
          const hasNumber = customersFound[0].address.number
            ? `, ${customersFound[0].address.number}`
            : "";

          const autocompleteText =
            customersFound[0].address.address + hasNumber;

          formRef.current?.setData({
            [`name_${index}`]: customersFound[0].name,
            [`autocomplete_${index}`]: autocompleteText,
            [`address_${index}`]: customersFound[0].address.address,
            [`complement_${index}`]: customersFound[0].address.complement || "",
            [`latitude_${index}`]: customersFound[0].address.latitude,
            [`longitude_${index}`]: customersFound[0].address.longitude,
            [`postal_code_${index}`]:
              customersFound[0].address.postal_code || "",
            [`number_${index}`]: customersFound[0].address.number || "",
            [`neighborhood_${index}`]: customersFound[0].address.neighborhood,
            [`city_${index}`]: customersFound[0].address.city.name,
            [`uf_${index}`]: customersFound[0].address.city.state.uf,
          });

          if (
            role !== process.env.REACT_APP_ADMIN_ROLE &&
            role !== process.env.REACT_APP_OPERATOR_ROLE &&
            role !== process.env.REACT_APP_FINANCIAL_ROLE
          ) {
            // TODO/BACKOFFICE: Pegar dados da company do input de company, caso não esteja preenchido travar o restante do form

            const params = new URLSearchParams({
              company_id: company_id ? company_id.toString() : "",
              return: returnValue === "true" ? "true" : "false",
              origin_latitude: companyLocation?.companyLat?.toString() || "",
              origin_longitude: companyLocation?.companyLng?.toString() || "",
              destination_latitude:
                customersFound[0].address.latitude?.toString() || "",
              destination_longitude:
                customersFound[0].address.longitude?.toString() || "",
              city_name: customersFound[0].address.city.name || "",
              uf: customersFound[0].address.city.state.uf || "",
            });
            const { data: valuePreviewData } =
              await apiCalc.get<CalcOrderResponse>(
                `orders/calc?${params.toString()}`,
              );

            const calculatedPreview =
              valuePreviewData.loocal_fee + valuePreviewData.cost;

            const { data: flagsData } = await api.get(
              `configsbackoffice/${companyLocation?.companyCityId}`,
            );

            setValuePreview({
              value: calculatedPreview,
              isReturnOff: !!flagsData?.return_cost_off,
            });
          }
        }
      } catch (e) {
        addToast(
          "Falha ao localizar cliente ou calcular pedido, entre em contato com o suporte!",
          {
            appearance: "warning",
            autoDismiss: true,
          },
        );
      }
      setIsLoading(false);
    },
    [
      setIsLoading,
      formRef,
      index,
      addToast,
      companyLocation,
      returnValue,
      role,
      company_id,
    ],
  );

  const onBlurVerifyPhone = useCallback(
    async (e) => {
      const phoneLength = e.target.value.length;
      if (phoneLength < 14 && phoneLength !== 0) {
        formRef.current?.setFieldValue(`phone_${index}`, "");
        formRef.current?.setFieldError(
          `phone_${index}`,
          "Telefone inválido, campo não obrigatório",
        );
      } else if (phoneLength !== 0) {
        const phone = e.target.value.replace(/\D/g, "");
        findCustomerByPhone(phone);
      }
    },
    [formRef, index, findCustomerByPhone],
  );

  const onChangeCheckIfReturn = useCallback(
    (e) => {
      if (e.label === "Online") {
        formRef.current?.setFieldValue(`return_${index}`, "false");
      } else {
        formRef.current?.setFieldValue(`return_${index}`, "true");
      }
    },
    [formRef, index],
  );

  const handleRecalcValuePreview = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setReturnValue(e.target.value);
      if (valuePreview !== null) {
        if (e.target.value === "true") {
          setValuePreview({ ...valuePreview, value: valuePreview.value + 1 });
        } else {
          setValuePreview({ ...valuePreview, value: valuePreview.value - 1 });
        }
      }
    },
    [valuePreview],
  );

  const handleRemoveNewOrder = useCallback(() => {
    setNewOrdersCount((state) => {
      return state.filter((_, i) => i !== index);
    });
  }, [index, setNewOrdersCount]);

  useEffect(() => {
    if (childrenStateUpdater) {
      setValuePreview(null);
    }
  }, [childrenStateUpdater]);

  useEffect(() => {
    formRef.current?.setFieldValue(`vehicles`, []);
  }, [formRef, index]);

  return (
    <Styled.Order
      isSidebarMaximized={isSidebarMaximized}
      isDispatch={isDispatch}
      isAdmin={
        role === process.env.REACT_APP_ADMIN_ROLE ||
        role === process.env.REACT_APP_OPERATOR_ROLE
      }
      index={index}
    >
      <Styled.TopBar
        isAdmin={
          role === process.env.REACT_APP_ADMIN_ROLE ||
          role === process.env.REACT_APP_OPERATOR_ROLE
        }
        index={index}
      >
        <Styled.Title>#{index + 1} - Criar novo pedido</Styled.Title>
        {index !== 0 && <FiXCircle size={25} onClick={handleRemoveNewOrder} />}
        {index === 0 && (
          <Styled.SwitchContainer>
            <Styled.Switch
              width={36}
              height={18}
              onChange={setIsDispatch}
              checked={isDispatch}
            />
            Enviar como despacho?
            <FiInfo
              size={18}
              data-tip="Pedidos ficarão na tela despachos aguardando montagem da rota e envio do delivery!"
            />
            <ReactTooltip />
          </Styled.SwitchContainer>
        )}
      </Styled.TopBar>
      {(role === process.env.REACT_APP_ADMIN_ROLE ||
        role === process.env.REACT_APP_OPERATOR_ROLE) &&
        index < 1 && (
          <>
            <Styled.Strong>Opções de Backoffice</Styled.Strong>
            <Styled.Row>
              <LoocalAutocompleteInput
                type="normal"
                id="company_name"
                name="company_name"
                placeholder="Estabelecimento"
                formRef={formRef}
                endpoint="company"
                icon={FiBriefcase}
              />
              <InvisibleInput type="text" id="company_id" name="company_id" />
              <InvisibleInput
                type="text"
                id="company_city_id"
                name="company_city_id"
              />
              <InvisibleInput
                type="text"
                id="company_latitude"
                name="company_latitude"
              />
              <InvisibleInput
                type="text"
                id="company_longitude"
                name="company_longitude"
              />
            </Styled.Row>
          </>
        )}
      {index === 0 && !isDispatch && (
        <>
          <Styled.Strong>Tipo de veículo</Styled.Strong>
          <VehiclesSelect
            selectedVehicles={selectedVehicles}
            setSelectedVehicles={setSelectedVehicles}
          />
        </>
      )}
      <Styled.Strong>
        Dados para entrega{" "}
        {valuePreview && (
          <span>
            Prévia de custo do pedido:{" "}
            <span>
              R$ {valuePreview.value.toFixed(2).toString().replace(".", ",")}
            </span>
          </span>
        )}
      </Styled.Strong>

      <Styled.Row>
        <Input
          type="text"
          id={`phone_${index}`}
          name={`phone_${index}`}
          placeholder="Telefone"
          onChange={onChangePhoneMask}
          onBlur={onBlurVerifyPhone}
          icon={FiPhone}
          maxLength={15}
        />
        <Input
          type="text"
          id={`name_${index}`}
          name={`name_${index}`}
          placeholder="Cliente *"
          icon={FiUser}
        />
      </Styled.Row>
      <Styled.Row>
        <AutocompleteInput
          type="text"
          id={`autocomplete_${index}`}
          name={`autocomplete_${index}`}
          index={index}
          setValuePreview={setValuePreview}
          placeholder="Endereço *"
          icon={FiCompass}
          formRef={formRef}
        />

        <InvisibleInput
          type="text"
          id={`address_${index}`}
          name={`address_${index}`}
        />
        <InvisibleInput
          type="text"
          id={`latitude_${index}`}
          name={`latitude_${index}`}
        />
        <InvisibleInput
          type="text"
          id={`longitude_${index}`}
          name={`longitude_${index}`}
        />
        <InvisibleInput
          type="text"
          id={`postal_code_${index}`}
          name={`postal_code_${index}`}
        />
        <InvisibleInput
          type="text"
          id={`number_${index}`}
          name={`number_${index}`}
        />
        <InvisibleInput
          type="text"
          id={`neighborhood_${index}`}
          name={`neighborhood_${index}`}
        />
        <InvisibleInput
          type="text"
          id={`city_${index}`}
          name={`city_${index}`}
        />
        <InvisibleInput type="text" id={`uf_${index}`} name={`uf_${index}`} />
        <InvisibleInput
          type="text"
          id={`packet_type_id_${index}`}
          name={`packet_type_id_${index}`}
          value="1"
          readOnly
        />
        <InvisibleInput
          type="text"
          id={`thermal_box_${index}`}
          name={`thermal_box_${index}`}
          value="true"
          readOnly
        />
        <InvisibleInput
          type="text"
          id={`order_status_id_${index}`}
          name={`order_status_id_${index}`}
          value="1"
          readOnly
        />

        <Input
          type="text"
          id={`complement_${index}`}
          name={`complement_${index}`}
          placeholder="Complemento"
          icon={FiMap}
        />
      </Styled.Row>
      <Styled.Strong>Dados de pagamento</Styled.Strong>
      <Styled.Row>
        <Select
          id={`payment_type_id_${index}`}
          name={`payment_type_id_${index}`}
          placeholder="Método de pagamento *"
          formRef={formRef}
          isSearchable={false}
          onChange={onChangeCheckIfReturn}
          options={[
            { value: "1", label: "Dinheiro" },
            { value: "2", label: "Cartão de crédito" },
            { value: "3", label: "Cartão de débito" },
            { value: "4", label: "Cartão refeição" },
            { value: "5", label: "Online" },
          ]}
          icon={FiCreditCard}
        />
        <Input
          type="text"
          id={`amount_${index}`}
          name={`amount_${index}`}
          placeholder="Valor *"
          icon={FiDollarSign}
          onChange={onChangeCurrencyMask}
          maxLength={10}
        />
        <Input
          type="text"
          id={`change_${index}`}
          name={`change_${index}`}
          placeholder="Troco"
          icon={FiDatabase}
          onChange={onChangeCurrencyMask}
          maxLength={10}
        />
      </Styled.Row>
      <Styled.Row>
        <Input
          type="text"
          id={`observation_${index}`}
          name={`observation_${index}`}
          placeholder="Observações"
          icon={FiEdit}
        />
      </Styled.Row>

      <Styled.Strong>Informações adicionais</Styled.Strong>

      <Styled.RadioRow>
        <Styled.RadioOptions>
          <p>Precisará retornar?</p>
          <Radio
            name={`return_${index}`}
            options={[
              { id: "true", label: "Sim" },
              { id: "false", label: "Não" },
            ]}
            onChange={
              valuePreview?.isReturnOff === false
                ? handleRecalcValuePreview
                : (e) => setReturnValue(e.target.value)
            }
            defaultValue="false"
          />
        </Styled.RadioOptions>
        <Styled.RadioOptions>
          <p>Precisará de assinatura?</p>
          <Radio
            name={`get_sign_${index}`}
            options={[
              { id: "true", label: "Sim" },
              { id: "false", label: "Não" },
            ]}
            defaultValue="false"
          />
        </Styled.RadioOptions>
      </Styled.RadioRow>
    </Styled.Order>
  );
};

export default NewOrderComponent;
