import React, { useCallback, useRef, useState, useMemo } from "react";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { FiFilePlus } from "react-icons/fi";

import { useSidebar } from "../../hooks/SidebarContext";
import { useAuth } from "../../hooks/AuthContext";

import api from "../../services/api";

import Sidebar from "../../components/Sidebar";
import Button from "../../components/Button";
import LoadingSpinner from "../../components/LoadingSpinner";
import NewOrderComponent from "../../components/NewOrderComponent";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";

import getValidationErrorsWithAutocomplete from "../../utils/getValidationErrorsWithAutocomplete";

import * as Styled from "./styles";
import apiV3 from "../../services/apiv3";
import apiCalc from "../../services/apiCalc";

interface CalcOrderResponse {
  distanceInMeters: number;
  cost: number;
  loocal_fee: number;
}
interface OrderFormData {
  [key: string]: string;
}

const Orders: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [newOrdersCount, setNewOrdersCount] = useState([0]);
  const [isDispatch, setIsDispatch] = useState(false);
  const [childrenStateUpdater, setChildrenStateUpdater] = useState(0);
  const [selectedVehicles, setSelectedVehicles] = useState([1, 2, 3]);

  const formRef = useRef<FormHandles>(null);

  const { isSidebarMaximized } = useSidebar();
  const { companyLocation, company_id } = useAuth();
  const { addToast } = useToasts();

  const addNewOrder = useCallback(
    async (data: OrderFormData) => {
      try {
        const orders = [];
        setIsLoading(true);
        for (let i = 0; i < newOrdersCount.length; i++) {
          const dataToAddNewOrder = {
            customer: {
              name: data[`name_${i}`],
              phones: data[`phone_${i}`] ? [data[`phone_${i}`]] : "",
              address: {
                ...(data[`postal_code_${i}`] !== "" && {
                  postal_code: data[`postal_code_${i}`].replace(/\D/g, ""),
                }),
                address: data[`address_${i}`],
                number: data[`number_${i}`],
                complement: data[`complement_${i}`],
                neighborhood: data[`neighborhood_${i}`],
                latitude: data[`latitude_${i}`],
                longitude: data[`longitude_${i}`],
                city: data[`city_${i}`],
                uf: data[`uf_${i}`],
              },
            },
            observation: data[`observation_${i}`],
            return: data[`return_${i}`] === "true",
            packet_type_id: data[`packet_type_id_${i}`],
            payment_type_id: data[`payment_type_id_${i}`],
            amount: data[`amount_${i}`],
            change: Number(data[`change_${i}`]),
            thermal_box: data[`thermal_box_${i}`] === "true",
            get_sign: false /* data[`get_sign_${i}`] === "true" */,
            order_status_id: data[`order_status_id_${i}`],
          };
          orders.push(dataToAddNewOrder);
        }

        await apiV3.post("deliveries", {
          company_id: data.company_id ? data.company_id : company_id,
          orders,
          vehicles: selectedVehicles,
        });

        setIsLoading(false);
        addToast("Pedido adicionado com sucesso!", {
          appearance: "success",
          autoDismiss: true,
        });
        formRef.current?.reset();
        setSelectedVehicles([1, 2, 3]);

        setNewOrdersCount([0]);
        setChildrenStateUpdater((state) => state + 1);
      } catch (err) {
        addToast("Erro ao adicionar pedido, entre em contato com o suporte!", {
          appearance: "warning",
          autoDismiss: true,
        });
        setIsLoading(false);
      }
    },
    [addToast, company_id, newOrdersCount, selectedVehicles],
  );

  const addNewDispatch = useCallback(
    async (data: OrderFormData) => {
      try {
        const orders = [];
        for (let i = 0; i < newOrdersCount.length; i++) {
          const dataToAddNewDispatch = {
            customer: {
              name: data[`name_${i}`],
              phones: data[`phone_${i}`] ? [data[`phone_${i}`]] : "",
              address: {
                ...(data[`postal_code_${i}`] !== "" && {
                  postal_code: data[`postal_code_${i}`].replace(/\D/g, ""),
                }),
                address: data[`address_${i}`],
                number: data[`number_${i}`],
                complement: data[`complement_${i}`],
                neighborhood: data[`neighborhood_${i}`],
                latitude: data[`latitude_${i}`],
                longitude: data[`longitude_${i}`],
                city: data[`city_${i}`],
                uf: data[`uf_${i}`],
              },
            },
            observation: data[`observation_${i}`],
            return: data[`return_${i}`] === "true",
            packet_type_id: data[`packet_type_id_${i}`],
            payment_type_id: data[`payment_type_id_${i}`],
            amount: data[`amount_${i}`],
            change: Number(data[`change_${i}`]),
            thermal_box: data[`thermal_box_${i}`] === "true",
            get_sign: false /* data[`get_sign_${i}`] === "true" */,
            order_status_id: data[`order_status_id_${i}`],
          };
          orders.push(dataToAddNewDispatch);
        }

        await apiV3.post("orders", {
          company_id: data.company_id ? data.company_id : company_id,
          orders,
        });

        setIsLoading(false);
        addToast("Despacho adicionado com sucesso!", {
          appearance: "success",
          autoDismiss: true,
        });
        formRef.current?.reset();

        setNewOrdersCount([0]);
        setChildrenStateUpdater((state) => state + 1);
      } catch (err) {
        addToast(
          "Erro ao adicionar despacho, entre em contato com o suporte!",
          {
            appearance: "warning",
            autoDismiss: true,
          },
        );
        setIsLoading(false);
      }
    },
    [addToast, company_id, newOrdersCount],
  );

  const renderSelectedVehiclesText = useCallback(() => {
    if (selectedVehicles.length === 3) return "";

    if (selectedVehicles.length === 1) {
      if (selectedVehicles.includes(1))
        return "As entregas serão exibidas APENAS para entregadores que possuam MOTO como veículo.";

      if (selectedVehicles.includes(2))
        return "As entregas serão exibidas APENAS para entregadores que possuam CARRO como veículo.";

      if (selectedVehicles.includes(3))
        return "As entregas serão exibidas APENAS para entregadores que possuam BICICLETA como veículo.";
    }

    if (selectedVehicles.length === 2) {
      if (selectedVehicles.includes(1) && selectedVehicles.includes(2))
        return "As entregas serão exibidas APENAS para entregadores que possuam CARRO e MOTO como veículo.";

      if (selectedVehicles.includes(1) && selectedVehicles.includes(3))
        return "As entregas serão exibidas APENAS para entregadores que possuam MOTO e BICICLETA como veículo.";

      if (selectedVehicles.includes(2) && selectedVehicles.includes(3))
        return "As entregas serão exibidas APENAS para entregadores que possuam CARRO e BICICLETA como veículo.";
    }

    return "";
  }, [selectedVehicles]);

  const calcOrderAndShowConfirmationModal = useCallback(
    async (data: OrderFormData) => {
      try {
        const calculatedOrdersData = [];
        for (let i = 0; i < newOrdersCount.length; i++) {
          const params = new URLSearchParams({
            company_id: data.company_id ? data.company_id : "",
            return: data[`return_${i}`] === "true" ? "true" : "false",
            origin_latitude:
              (data.company_latitude
                ? data.company_latitude
                : companyLocation?.companyLat
              )?.toString() || "",
            origin_longitude:
              (data.company_longitude
                ? data.company_longitude
                : companyLocation?.companyLng
              )?.toString() || "",
            destination_latitude: data[`latitude_${i}`]?.toString() || "",
            destination_longitude: data[`longitude_${i}`]?.toString() || "",
            city_name: data[`city_${i}`] || "",
            uf: data[`uf_${i}`] || "",
          });

          const { data: calcOrderResponse } =
            await apiCalc.get<CalcOrderResponse>(
              `orders/calc?${params.toString()}`,
            );
          calculatedOrdersData.push(calcOrderResponse);
        }

        let totalCost = 0;
        calculatedOrdersData.forEach((calculatedOrder) => {
          totalCost = calculatedOrder.cost + 2 + totalCost;
        });

        const { data: companyResponseData } = await api.get(
          `companies/${data.company_id ? data.company_id : company_id}`,
        );

        const insufficientFunds = totalCost > companyResponseData.balance;

        const selectedVehiclesText = renderSelectedVehiclesText();

        let selectedVehiclesModalHTML = ``;

        if (selectedVehiclesText && !isDispatch) {
          selectedVehiclesModalHTML = `
              <div class="vehicles-info">
                <p class="vehicles-info-text">${selectedVehiclesText}</p>
                <p class="vehicles-info-text">Caso queira maior agilidade e o tamanho do produto seja compatível, opte sempre em acionar todos os tipos de veículos.</p>
              </div>
          `;
        }

        const modalOptions: SweetAlertOptions = {
          icon: "warning",
          title: "Confirmar pedido(s)?",
          showCancelButton: true,
          confirmButtonColor: "var(--color-primary)",
          cancelButtonColor: "var(--color-danger)",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          ...(insufficientFunds && {
            footer:
              "<strong class='swal-error'>Saldo Insuficiente</strong class='swal-error'>",
          }),
          didOpen: () => {
            if (insufficientFunds) {
              document
                .querySelector(".swal2-confirm")
                ?.classList.add("swal-disabled");
            }
          },
          html: `
              <style>
              .subtotal-confirm {
                width: 100%
              }
              .vehicles-info {
                margin-top: 20px;
                padding: 10px 0 15px;
                border-top: 1px solid lightgray;
                border-bottom: 1px solid lightgray;
              }
              .vehicles-info-text {
                font-size: 13px;
                margin-top: 5px;
              }
              </style>
              <table class="subtotal-confirm">
              <thead>
                <tr>
                  <th></th>
                  <th>Distância</th>
                  <th>Custo</th>
                </tr>
              </thead>
              <tbody>
              ${calculatedOrdersData.map((calculatedOrderData, index) => {
                return `
                    <tr>
                      <td><strong>Pedido ${index + 1}</strong></td>
                      <td>${(calculatedOrderData.distanceInMeters / 1000)
                        .toFixed(2)
                        .replace(".", ",")} km</td>
                      <td>R$ ${(
                        calculatedOrderData.cost +
                        calculatedOrderData.loocal_fee
                      )
                        .toFixed(2)
                        .replace(".", ",")}</td>
                    </tr>
                  `;
              })}

              </tbody>
              </table>
              ${selectedVehiclesModalHTML}
              `,
        };
        setIsLoading(false);
        const { isConfirmed } = await Swal.fire(modalOptions);

        if (isConfirmed) {
          setIsLoading(true);
          if (isDispatch) {
            addNewDispatch(data);
          } else {
            addNewOrder(data);
          }
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        addToast("Erro ao calcular pedido, entre em contato com o suporte!", {
          appearance: "warning",
          autoDismiss: true,
        });
        setIsLoading(false);
      }
    },
    [
      companyLocation,
      addNewOrder,
      newOrdersCount,
      addToast,
      company_id,
      isDispatch,
      addNewDispatch,
      renderSelectedVehiclesText,
    ],
  );

  const handleSubmitForm = useCallback(
    async (data: OrderFormData) => {
      setIsLoading(true);
      try {
        for (let i = 0; i < newOrdersCount.length; i++) {
          data[`amount_${i}`] = data[`amount_${i}`]
            .replace(/[^0-9,-]+/g, "")
            .replace(/,/g, ".");

          data[`change_${i}`] = data[`change_${i}`]
            .replace(/[^0-9,-]+/g, "")
            .replace(/,/g, ".");

          data[`phone_${i}`] =
            data[`phone_${i}`] && data[`phone_${i}`].replace(/\D/g, "");

          const schema = Yup.object().shape({
            [`name_${i}`]: Yup.string().min(3, "Nome inválido"),
            [`autocomplete_${i}`]: Yup.string().min(6, "Endereço inválido"),
            [`amount_${i}`]: Yup.string().required("Valor inválido"),
            [`payment_type_id_${i}`]: Yup.number()
              .moreThan(0.99, "Selecione um método de pagamento")
              .typeError("Selecione um método de pagamento"),
            [`number_${i}`]: Yup.string().required(
              "Você precisa digitar um número",
            ),
            [`address_${i}`]: Yup.string().required("Endereço inválido"),
            [`complement_${i}`]: Yup.string().when(`number_${i}`, {
              is: "s/n",
              then: Yup.string().required(
                "Complemento necessário para endereços sem número",
              ),
            }),
          });
          await schema.validate(data, { abortEarly: false });
        }
        calcOrderAndShowConfirmationModal(data);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrorsWithAutocomplete(err);
          formRef.current?.setErrors(errors);
          const erroredElement = document.getElementById(
            err.inner[0].path as string,
          );
          if (erroredElement) {
            window.scrollTo({
              top: erroredElement?.offsetTop,
              behavior: "smooth",
            });
          }
        }
        setIsLoading(false);
      }
    },
    [calcOrderAndShowConfirmationModal, newOrdersCount],
  );

  const handleAddNewOrderComponent = useCallback(() => {
    setNewOrdersCount((state) => {
      const stLen = state.length;
      return [...state, state[stLen - 1] + 1];
    });
  }, []);

  const submitButtonText = useMemo(() => {
    if (isLoading) return "Carregando...";
    if (isDispatch) return "Salvar Despacho";

    return "Acionar Delivery";
  }, [isDispatch, isLoading]);

  return (
    <Container>
      <Sidebar />
      {isLoading && <LoadingSpinner />}
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.NewOrdersContainer>
          <Styled.SForm
            onSubmit={handleSubmitForm}
            ref={formRef}
            autoComplete="off"
          >
            {newOrdersCount.map((_, index) => (
              <NewOrderComponent
                formRef={formRef}
                index={index}
                key={index}
                childrenStateUpdater={childrenStateUpdater}
                isDispatch={isDispatch}
                setIsDispatch={setIsDispatch}
                setNewOrdersCount={setNewOrdersCount}
                setIsLoading={setIsLoading}
                setSelectedVehicles={setSelectedVehicles}
                selectedVehicles={selectedVehicles}
              />
            ))}

            <Styled.FormFooter>
              <Styled.AddNewOrder onClick={handleAddNewOrderComponent}>
                <FiFilePlus size={27} />
                <span>Adicionar nova entrega</span>
              </Styled.AddNewOrder>
              <Button
                type="submit"
                customColor={isDispatch ? "#ffea00" : null}
                content={submitButtonText}
                disabled={isLoading}
              />
            </Styled.FormFooter>
          </Styled.SForm>
        </Styled.NewOrdersContainer>
      </ContainerMain>
    </Container>
  );
};

export default Orders;
