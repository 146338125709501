export const states = [
  { id: 1, uf: "AC" },
  { id: 2, uf: "AL" },
  { id: 3, uf: "AM" },
  { id: 4, uf: "AP" },
  { id: 5, uf: "BA" },
  { id: 6, uf: "CE" },
  { id: 7, uf: "DF" },
  { id: 8, uf: "ES" },
  { id: 9, uf: "GO" },
  { id: 10, uf: "MA" },
  { id: 11, uf: "MG" },
  { id: 12, uf: "MS" },
  { id: 13, uf: "MT" },
  { id: 14, uf: "PA" },
  { id: 15, uf: "PB" },
  { id: 16, uf: "PE" },
  { id: 17, uf: "PI" },
  { id: 18, uf: "PR" },
  { id: 19, uf: "RJ" },
  { id: 20, uf: "RN" },
  { id: 21, uf: "RO" },
  { id: 22, uf: "RR" },
  { id: 23, uf: "RS" },
  { id: 24, uf: "SC" },
  { id: 25, uf: "SE" },
  { id: 26, uf: "SP" },
  { id: 27, uf: "TO" },
];

export const getUfByStateId = (stateId: string): string | null => {
  const state = states.find((s) => s.id === parseInt(stateId, 10));
  return state ? state.uf : null;
};
