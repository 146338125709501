/* eslint-disable */
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormHandles, useField } from "@unform/core";
import { FiAlertCircle } from "react-icons/fi";
import { IconBaseProps } from "react-icons";
import { useToasts } from "react-toast-notifications";
import { debounce } from "lodash";
import Autosuggest, {
  SuggestionsFetchRequestedParams,
} from "react-autosuggest";

import { useAuth } from "../../hooks/AuthContext";

import api from "../../services/api";

import * as Styled from "./styles";
import useStateCallback from "../../hooks/UseStateCallback";
import apiCalc from "../../services/apiCalc";

interface CalcOrderResponse {
  distanceInMeters: number;
  cost: number;
  loocal_fee: number;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  index: number;
  formRef: React.RefObject<FormHandles>;
  setValuePreview?: React.Dispatch<
    React.SetStateAction<{ value: number; isReturnOff: boolean } | null>
  >;
}

interface DataObjectToFillForm {
  latitude: number;
  longitude: number;
  number: string;
  address: string;
  neighborhood: string;
  city: string;
  uf: string;
  postal_code: string;
}

const AutocompleteInput: React.FC<InputProps> = ({
  icon: Icon,
  name,
  formRef,
  setValuePreview,
  placeholder,
  index,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isAddressAlreadySelected, SetIsAddressAlreadySelected] =
    useState(false);
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [autosuggestionInputValue, setAutosuggestionInputValue] =
    useStateCallback("");
  const [hasNumberError, setHasNumberError] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const autocompleteToken =
    useRef<google.maps.places.AutocompleteSessionToken | null>(null);
  const autocompleteBounds = useRef<google.maps.LatLngBounds | null>(null);
  const { fieldName, registerField, error, clearError } = useField(name);

  const { companyLocation, role, company_id } = useAuth();
  const { addToast } = useToasts();

  const NUMBER_MASK = "número";

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      setValue: (ref, value) => {
        if (value) {
          setAutosuggestionInputValue(value as string);
          SetIsAddressAlreadySelected(true);
          ref.value = value;
        }
      },
      clearValue: () => {
        setAutosuggestionInputValue("");
      },
    });
  }, [fieldName, setAutosuggestionInputValue, registerField]);

  const geolocate = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        });
        autocompleteBounds.current =
          circle.getBounds() as google.maps.LatLngBounds;
      });
    }
  }, []);

  const handleClearValues = useCallback(() => {
    if (isAddressAlreadySelected) {
      formRef.current?.setData({
        [`address_${index}`]: "",
        [`latitude_${index}`]: "",
        [`longitude_${index}`]: "",
        [`postal_code_${index}`]: "",
        [`number_${index}`]: "",
        [`neighborhood_${index}`]: "",
        [`city_${index}`]: "",
        [`uf_${index}`]: "",
      });
      SetIsAddressAlreadySelected(false);
    }
  }, [isAddressAlreadySelected, index, formRef]);

  const handleChooseAddress = useCallback(
    async (data: DataObjectToFillForm) => {
      async function UpdateFormDataAndCalcPreview() {
        formRef.current?.setData({
          [`address_${index}`]: data.address,
          [`latitude_${index}`]: data.latitude,
          [`longitude_${index}`]: data.longitude,
          [`postal_code_${index}`]: data.postal_code,
          [`number_${index}`]: data.number?.toLowerCase(),
          [`neighborhood_${index}`]: data.neighborhood,
          [`city_${index}`]: data.city,
          [`uf_${index}`]: data.uf,
        });

        if (setValuePreview) {
          if (
            role !== process.env.REACT_APP_ADMIN_ROLE &&
            role !== process.env.REACT_APP_OPERATOR_ROLE &&
            role !== process.env.REACT_APP_FINANCIAL_ROLE
          ) {
            // TODO/BACKOFFICE: Pegar dados da company do input de company, caso não esteja preenchido travar o restante do form - ref: NewOrderComp. calc;
            try {
              const needReturn = formRef.current?.getFieldValue(
                `return_${index}`,
              );

              const params = new URLSearchParams({
                company_id: company_id ? company_id.toString() : "",
                return: needReturn === "true" ? "true" : "false",
                origin_latitude: companyLocation?.companyLat?.toString() || "",
                origin_longitude: companyLocation?.companyLng?.toString() || "",
                destination_latitude: data.latitude?.toString() || "",
                destination_longitude: data.longitude?.toString() || "",
                city_name: data.city || "",
                uf: data.uf || "",
              });

              const { data: valuePreview } =
                await apiCalc.get<CalcOrderResponse>(
                  `orders/calc?${params.toString()}`,
                );

              const calculatedPreview =
                valuePreview.loocal_fee + valuePreview.cost;

              const { data: flagsData } = await api.get(
                `configsbackoffice/${companyLocation?.companyCityId}`,
              );

              setValuePreview({
                value: calculatedPreview,
                isReturnOff: flagsData.return_cost_off,
              });
            } catch (e) {
              addToast(
                "Falha ao calcular pedido, entre em contato com o suporte!",
                {
                  appearance: "warning",
                  autoDismiss: true,
                },
              );
            }
          }
        }
        SetIsAddressAlreadySelected(true);
      }

      UpdateFormDataAndCalcPreview();
    },
    [
      formRef,
      companyLocation,
      setValuePreview,
      index,
      role,
      addToast,
      company_id,
    ],
  );

  const addressHasNumber = useCallback(
    (address: google.maps.places.AutocompletePrediction | string) => {
      if (!address) {
        return false;
      }

      if (typeof address === "string") {
        return !!address.match(/, \d/) || !!address.match(/, \W*(número)\W*/);
      }

      return false;
    },
    [],
  );

  const handleSelectNumberMask = useCallback((formattedAddress: string) => {
    const start = formattedAddress.indexOf(NUMBER_MASK);
    const end = start + NUMBER_MASK.length;

    inputRef.current?.setSelectionRange(start, end);
  }, []);

  const handleSetIsFocused = useCallback(() => {
    if (hasNumberError) {
      handleSelectNumberMask(inputRef.current?.value as string);
      return;
    }

    clearError();
    setIsFocused(true);
    geolocate();
  }, [clearError, geolocate, handleSelectNumberMask, hasNumberError]);

  const handlePlaceSelect = useCallback(
    (selectedSuggestion: google.maps.places.AutocompletePrediction) => {
      if (setValuePreview) {
        setValuePreview(null);
      }
      const hasNumber = addressHasNumber(selectedSuggestion.description);

      if (!hasNumber) {
        const formattedAddress = `${selectedSuggestion.structured_formatting.main_text}, ${NUMBER_MASK}`;
        setHasNumberError(true);
        setAutosuggestionInputValue(formattedAddress, () => {
          handleSelectNumberMask(formattedAddress);
          formRef.current?.setFieldError(
            `autocomplete_${index}`,
            "Você precisa digitar um número",
          );
        });

        return;
      }

      setAutosuggestionInputValue(
        selectedSuggestion.structured_formatting.main_text.replace(
          /\W*(número)\W*/i,
          ", ",
        ),
      );

      const service = new google.maps.places.PlacesService(
        document.createElement("div"),
      );
      const request: google.maps.places.PlaceDetailsRequest = {
        placeId: selectedSuggestion.place_id,
        sessionToken:
          autocompleteToken.current as google.maps.places.AutocompleteSessionToken,
        fields: ["address_components", "geometry"],
      };

      service.getDetails(request, (addressObject, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          autocompleteToken.current = null;
          if (addressObject?.address_components) {
            const { address_components } = addressObject;
            const latitude = addressObject.geometry?.location?.lat();
            const longitude = addressObject.geometry?.location?.lng();

            const dataObject: DataObjectToFillForm = {
              latitude,
              longitude,
            } as DataObjectToFillForm;

            address_components.forEach((item) => {
              switch (item.types[0]) {
                case "street_number":
                  dataObject.number = item.long_name;
                  break;
                case "route":
                  dataObject.address = item.long_name;
                  break;
                case "sublocality_level_1":
                  dataObject.neighborhood = item.long_name;
                  break;
                case "administrative_area_level_2":
                  dataObject.city = item.long_name;
                  break;
                case "administrative_area_level_1":
                  dataObject.uf = item.short_name;
                  break;
                case "postal_code":
                  dataObject.postal_code = item.long_name;
                  break;
                default:
                  break;
              }
            });

            if (!("number" in dataObject) && hasNumber) {
              const number =
                selectedSuggestion.structured_formatting.main_text.split(
                  ", ",
                )[1];

              dataObject.number = number;
            }
            handleChooseAddress(dataObject);
          }
        }
      });
    },
    [
      setValuePreview,
      addressHasNumber,
      formRef,
      index,
      setAutosuggestionInputValue,
      handleSelectNumberMask,
      handleChooseAddress,
    ],
  );

  const handleInputKeyDown = useCallback(() => {
    if (hasNumberError) {
      setHasNumberError(false);
      clearError();
    }

    handleClearValues();
  }, [hasNumberError, handleClearValues, clearError]);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const addressAutocomplete = useCallback(
    (request: SuggestionsFetchRequestedParams) => {
      const autocomplete = new google.maps.places.AutocompleteService();

      if (!autocompleteToken.current) {
        autocompleteToken.current =
          new google.maps.places.AutocompleteSessionToken();
      }

      const payload = {
        input: request.value,
        bounds: autocompleteBounds.current as google.maps.LatLngBounds,
        types: ["address"],
        componentRestrictions: { country: "BR" },
        sessionToken:
          autocompleteToken.current as google.maps.places.AutocompleteSessionToken,
      };
      if (request.value.length >= 6) {
        autocomplete.getPlacePredictions(payload, (predictionsRaw, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            setPredictions([]);
            return;
          }
          setPredictions(
            predictionsRaw as google.maps.places.AutocompletePrediction[],
          );
        });

        setTimeout(() => {
          setPredictions([]);
        }, 5000);
      }
    },
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPredictions = useCallback(
    debounce((input: any) => addressAutocomplete(input), 500),
    [addressAutocomplete],
  );

  const renderSuggestion = (
    suggestion: google.maps.places.AutocompletePrediction,
  ) => {
    return <span>{suggestion.description}</span>;
  };

  return (
    <Styled.Container
      ref={containerRef}
      isFocused={isFocused}
      isErrored={!!error}
      hasContent={!!autosuggestionInputValue}
    >
      {Icon && <Icon size={20} />}
      <Autosuggest
        suggestions={predictions}
        onSuggestionsFetchRequested={getPredictions}
        onSuggestionsClearRequested={() => setPredictions([])}
        onSuggestionSelected={(_, { suggestion }) =>
          handlePlaceSelect(suggestion)
        }
        getSuggestionValue={(sug) => sug?.description}
        renderSuggestion={renderSuggestion}
        inputProps={{
          value: autosuggestionInputValue,
          onChange: (_, { newValue }) => setAutosuggestionInputValue(newValue),
          onKeyDown: handleInputKeyDown,
          onFocus: handleSetIsFocused,
          onBlur: handleInputBlur,
          autoComplete: "nope",
          ref: inputRef,
        }}
      />

      <label htmlFor="autocomplete">{placeholder}</label>
      {error && (
        <Styled.Error title={error}>
          <FiAlertCircle size={20} />
        </Styled.Error>
      )}
    </Styled.Container>
  );
};

export default AutocompleteInput;
