import axios from "axios";
import * as Sentry from "@sentry/react";

const tokenU = () => {
  return localStorage.getItem("@Loocal-client:token");
}
const baseURL = !process.env.REACT_APP_DEV_MODE
  ? "https://api-production-v3.loocalapp.com/api/"
  : "https://api-developer-v3.loocalapp.com/api/";

const apiV3 = axios.create({
  baseURL,
  timeout: 60000,
});

apiV3.defaults.headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${tokenU()}`,
};

apiV3.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!process.env.REACT_APP_DEV_MODE) {
      const errorDetails = {
        endpoint: error.response.config.url || "n/a",
        reqBody: JSON.stringify(error.response.config.data),
        errorBody: JSON.stringify(error.response.data),
        status: error.response.status,
      };

      error.message = `${errorDetails.endpoint} - ${error.message}`;

      Sentry.captureException(error, (scope) => scope.setExtras(errorDetails));
    }
    const expiration = localStorage.getItem("@Loocal-client:expiration");
    if (expiration && error.response?.status === 401) {
      const now = new Date();
      const isExpirated = Number(expiration) < now.getTime();
      if (isExpirated) {
        localStorage.clear();
        window.location.reload();
        return Promise.reject(
          new Error("Bad news bro, the token has expired :("),
        );
      }
    }
    return Promise.reject(error);
  },
);

export default apiV3;
