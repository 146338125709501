import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export const formatLocale = (
  dateString: string | Date,
  formatString: string,
): string => {
  if (dateString === null) return "";
  const zonedDate = utcToZonedTime(new Date(dateString), "America/Sao_Paulo");
  return format(zonedDate, formatString);
};

export const formatLocaleSP = (
  dateString: string | Date,
  formatString: string,
): string => {
  if (dateString === null) return "";
  const zonedDate = utcToZonedTime(new Date(dateString), "America/Sao_Paulo");
  return format(zonedDate, formatString);
};

export const formatDateToSaoPaulo = (dateString: string): string => {
  const date = new Date(dateString);

  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");

  return `${day}/${month}/${year} - ${hours}:${minutes}`;
};
